import WtLogo from '../assets/wt-logo.png'
import ChimePhoto from '../assets/chime.png'
import GlobePhoto from '../assets/globe.png'
export const siteData = {
    homePageImageSrc: 'assets/home-image.png',
    homePageImageAlt: 'jack in chair',
    sections: [
        {
            title: 'software',
            slug: 'software',
            slides: [
                {
                    title: 'WeTransfer',
                    slug: 'wetransfer',
                    subTitle: 'fullstack engineer',
                    date: '2021-2024',
                    description:
                        'Designed and built performant & high-traffic APIs, leveraging AWS services like S3, RDS, and SQS. I implemented accessible and intuitive UI systems with React, Redux, and NextJS.',
                    tags: [
                        'React',
                        'Redux',
                        'NextJS',
                        'Node',
                        'NestJS',
                        'Kubernetes',
                        'Terraform',
                        'AWS',
                        'MySQL',
                        'SQS',
                        'ORMs',
                        'Database Design',
                    ],
                    imageSrc: WtLogo,
                    imageAlt: 'WT Logo',
                    slideLink: 'https://wetransfer.com',
                    isSlideTextCentric: true,
                    canInvertSlideImage: true,
                },
                {
                    title: 'Cargo',
                    slug: 'cargo',
                    subTitle: 'front-end developer',
                    date: '2021',
                    description:
                        'Implemented multi-user inline editing software with React, Redux, Node, and YJS. Designed and built a multi-user custom HTML / CSS code editor with codemirror',
                    tags: [
                        'React',
                        'Redux',
                        'YJS',
                        'CodeMirror',
                        'CRDTs',
                        'Node',
                        'Websockets',
                        'Inline Editing',
                    ],
                    imageSrc: GlobePhoto,
                    slideLink: 'https://cargo.site',
                    imageAlt: 'Cargo Globe',
                    isSlideTextCentric: true,
                    canInvertSlideImage: true,
                },
                {
                    title: 'React Sequencer',
                    slug: 'react-sequencer',
                    date: '2020',
                    description:
                        'A flexible and compact multi-instrument sequencer, built with React and Tone. The user can chose from different instruments and effects to create unique rhythms and sounds.',
                    tags: ['React', 'ToneJS', 'Web Audio API'],
                    imageSrc:
                        'https://res.cloudinary.com/dcttcffbc/image/upload/v1590011089/portfolio_images/react-sequencer1.png',
                    imageAlt: 'sequencer',
                    slideLink: 'https://jaqarrick.github.io/react-sequencer/',
                    sourceLink: 'https://github.com/jaqarrick/react-sequencer',
                },
                {
                    title: 'Wave Slicer',
                    slug: 'wave-slicer',
                    date: '2020',
                    description:
                        'A lightweight and minimal audio sampling web application. Designed as a simpler alternative to DAW samplers, users can upload audio files, select and name custom samples, and download them with one click.',
                    slideLink: 'https://jaqarrick.github.io/react-sequencer/',
                    sourceLink: 'https://github.com/jaqarrick/wave-slicer',
                    imageSrc:
                        'https://res.cloudinary.com/dcttcffbc/image/upload/v1608860363/portfolio_images/waveslicer.png',
                    imageAlt: 'Wave Slicer',
                    tags: ['React', 'Typescript', 'Web Audio', 'ToneJS'],
                },
                {
                    title: 'Jours',
                    slug: 'jours',
                    date: '2021',
                    description:
                        'CLI designed for safe secure journaling using AES-256 encryption.',
                    tags: ['Shell', 'OpenSSL'],
                    imageSrc:
                        'https://res.cloudinary.com/dcttcffbc/image/upload/v1645312432/portfolio_images/jours-demo.gif',
                    imageAlt: 'Jours CLI',
                    slideLink: 'https://github.com/jaqarrick/jours',
                    sourceLink: 'https://github.com/jaqarrick/jours',
                },
                {
                    title: 'Convergence',
                    slug: 'convergence',
                    date: '2020',
                    description:
                        '(Prototype) Convergence is a virtual performance space for collaboration and experimentation. Users can create and join performance rooms, customize them with audio effects and reverb, and record the sounds they create directly in the browser.',
                    tags: ['React', 'Node', 'Websockets', 'UI Design'],
                    imageSrc:
                        'https://res.cloudinary.com/dcttcffbc/image/upload/v1645485692/portfolio_images/convergence.gif',
                    imageAlt: 'Convergence',
                    tags: [
                        'React',
                        'Node',
                        'Typescript',
                        'SocketIO',
                        'WebRTC',
                        'ToneJS',
                        'Web Audio',
                    ],
                    slideLink: 'https://github.com/jaqarrick/convergence',
                    sourceLink: 'https://github.com/jaqarrick/convergence',
                },
            ],
        },
        {
            title: 'sound',
            slug: 'sound',
            slides: [
                {
                    title: 'Weather Systems',
                    slug: 'weather-systems',
                    date: '2021',
                    imageSrc: ChimePhoto,
                    imageAlt: 'Weather Systems Chime',

                    description:
                        'A self-activated wind chime that responds to weather data from any location in the world',
                    tags: [
                        'C++',
                        'Node',
                        'Arduino',
                        'Raspberry Pi',
                        'Recycled materials',
                    ],
                    slideLink: 'https://github.com/jaqarrick/weather-systems',
                    sourceLink: 'https://github.com/jaqarrick/weather-systems',
                },
                {
                    title: 'Double Tape',
                    slug: 'double-tape',
                    date: '2019',
                    description:
                        "Samples from a blank tape. Crackles, distortion, hisses, and buzzes altered into synthesizers and percussive instruments then played and recorded back on the original tape. Double Tape is the tape player's performance of its own composition.",
                    imageSrc:
                        'https://res.cloudinary.com/dcttcffbc/image/upload/f_auto,q_auto/v1/portfolio_images/double-tape2',
                    imageAlt: 'Double Tape',
                    slideLink:
                        'https://ext-tech.bandcamp.com/album/extended-techniques',
                },
                {
                    title: 'Extended Techniques',
                    slug: 'extended-techniques',
                    date: '2019',
                    description:
                        'Extended Techniques is an exercise in expansion. Each piece takes a popular instrument and attempts to expand its sonic capabilities through analogue and digital processes.',
                    imageSrc:
                        'https://res.cloudinary.com/dcttcffbc/image/upload/f_auto,q_auto/v1/portfolio_images/extended_techniques_artwork6_small',
                    imageAlt: 'Extended Techniques',
                    slideLink:
                        'https://ext-tech.bandcamp.com/album/extended-techniques',
                },
            ],
        },
        {
            title: 'archive',
            slug: 'archive',
            slides: [],
        },
    ],
}
