import { useContext } from "react"
import SiteContext from "../../../contexts/SiteContext/SiteContext"

export const Rss = ({ href = 'https://jackcarrick.net/rss.xml' }) => {

    const { colorTheme } = useContext(SiteContext)
    const styles = {
        fill: colorTheme.textColor,
    }
    return <a href={href}>
        <svg style={styles} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0v24h24v-24h-24zm6.168 20c-1.197 0-2.168-.969-2.168-2.165s.971-2.165 2.168-2.165 2.167.969 2.167 2.165-.97 2.165-2.167 2.165zm5.18 0c-.041-4.029-3.314-7.298-7.348-7.339v-3.207c5.814.041 10.518 4.739 10.56 10.546h-3.212zm5.441 0c-.021-7.063-5.736-12.761-12.789-12.792v-3.208c8.83.031 15.98 7.179 16 16h-3.211z" /></svg>
    </a>
}