import { createRef, useContext, useEffect, useMemo, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import SiteContext from "../../contexts/SiteContext/SiteContext"
import getStyles from "./Blog.styles"
import { css } from "aphrodite"
import './Post.css'
import NavigationButton from "../NavigationButton"

export const Post = () => {

    const params = useParams()
    const navigate = useNavigate();
    const { posts ,colorTheme, isMobileView } = useContext(SiteContext)

    const styles = getStyles(colorTheme, isMobileView)
    const [post, setPost] = useState(null)

    const previousPostSlug = useMemo(() => {
        const index = posts.findIndex((post) => post.slug === params.slug)
        if (index === -1 || index === posts.length - 1) {
            return
        }

        return posts[index + 1].slug
    }, [params, posts])

    const nextPostSlug = useMemo(() => {
        const index = posts.findIndex((post) => post.slug === params.slug)
        if (index === -1 || index === 0) {
            return
        }
        return posts[index - 1].slug
    }, [params, posts])

    useEffect(() => {
        if (posts.length === 0) {
            return
        }
        const currentPost = posts.find((post) => post.slug === params.slug)
        if (!currentPost) {
            navigate("/blog")
        }
        setPost(currentPost)
    }, [params, posts, navigate])


    const handleBackArrowClick = () => {
        navigate("/blog")
    }


    const containerRef = createRef()

    const handleClick = () => {
        containerRef.current.scrollTo(0, 0);
    }
    const footerLinkColor = colorTheme.accentColor

    const footerStyle = {
        color: footerLinkColor
    }

    const postStyle = {
        color: colorTheme.textColor
    }
    return (<>
        {post && <div ref={containerRef} className={css(styles.blogContainer)} >
            <NavigationButton onClick={handleBackArrowClick} label="left" /><div style={postStyle} className="postContainer" dangerouslySetInnerHTML={{ __html: post.html }} />
            <footer style={footerStyle} className="postFooter">
                <p>{previousPostSlug && <Link onClick={handleClick} to={`/blog/${previousPostSlug}`}>previous</Link>}</p>
                <p><Link to="/blog">home</Link></p>
                <p>{nextPostSlug && <Link onClick={handleClick} to={`/blog/${nextPostSlug}`}>next</Link>}</p>
            </footer>
        </div>}
    </>
    )
}