import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import styles from './SwipableSection.styles'
import { SwipableSection } from '../SwipableSection/SwipableSection'
import SwipeableViews from 'react-swipeable-views'
import { siteData } from '../../data/siteData'
import { useParams } from 'react-router-dom'
import Home from '../Home'
import SiteContext from '../../contexts/SiteContext/SiteContext'
import { cursorPositions } from '../../contexts/SiteContext/constants'

export const SwipableSections = () => {
    const params = useParams()
    const getSectionIndex = () => {
        const index = siteData.sections.findIndex(
            (section) => section.slug === params.section
        )
        return index > -1 ? index + 1 : 0
    }

    const getSlideIndex = (slides) => {
        const index = slides.findIndex(
            (slide) => slide.slug === params.slideSlug
        )
        return index > -1 ? index : 0
    }
    const {
        sectionIndex,
        setSectionIndex,
        setSlideIndex,
        move,
        cursorDirection,
        shouldAnimateSlideTransition,
        isMobileDevice,
    } = useContext(SiteContext)

    useEffect(() => {
        // determine current section and slide from params only on first render
        const sectionIndex = getSectionIndex()
        if (sectionIndex > 0) {
            const sectionSlides = siteData.sections[sectionIndex - 1].slides
            const slideIndex = getSlideIndex(sectionSlides)
            setSlideIndex(slideIndex > -1 ? slideIndex : 0)
        } else {
            setSlideIndex(0)
        }

        setSectionIndex(sectionIndex)
    }, [])

    const onChangeSection = (index) => {
        setSectionIndex(index)
        setSlideIndex(0)
    }

    const onClick = useCallback(() => {
        move(cursorDirection)
    }, [move, cursorDirection])

    const cursorStyles = useMemo(() => {
        return {
            cursor: cursorPositions[cursorDirection] ?? 'default',
        }
    }, [cursorDirection])

    return (
        <div style={cursorStyles} onClick={isMobileDevice ? null : onClick}>
            <SwipeableViews
                index={sectionIndex}
                animateTransitions={shouldAnimateSlideTransition}
                onChangeIndex={onChangeSection}
                containerStyle={Object.assign({}, styles.slideContainer)}
                axis="y"
                enableMouseEvents={true}
            >
                <Home />
                {siteData.sections.map((section) => (
                    <SwipableSection
                        key={section.title}
                        slides={section.slides}
                        sectionSlug={section.slug}
                    />
                ))}
            </SwipeableViews>
        </div>
    )
}
